<template>
  <div>
    <v-carousel
      cycle
      show-arrows-on-hover
      hide-delimiters
      progress
      progress-color="yellow"
      interval="2000"
      height="30vh"
    >
      <v-carousel-item
        v-for="(item, idx) in images"
        :key="idx"
        :src="`img/bookshop/${item.link}`"
        contains
      >
        <v-overlay
          :absolute="true"
          :overlay-opacity="0.5"
          :value="true"
        ></v-overlay>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: "Bookcarousel",
  data: () => ({
    images: [
      {
        link: "1.jpg",
      },
      {
        link: "2.jpg",
      },
      {
        link: "3.jpg",
      },
      {
        link: "4.jpg",
      },
      {
        link: "5.jpg",
      },
      {
        link: "6.jpg",
      },
      {
        link: "7.jpg",
      },
      {
        link: "8.jpg",
      },
      {
        link: "9.jpg",
      },
      {
        link: "10.jpg",
      },
      {
        link: "11.jpg",
      },
      {
        link: "12.jpg",
      },
      {
        link: "13.jpg",
      },
      {
        link: "14.jpg",
      },
      {
        link: "15.jpg",
      },
    ],
  }),
};
</script>
