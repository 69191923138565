<template>
  <div>
    <bread-crumb :items="items"></bread-crumb>
    <v-container>
      <book-carousel></book-carousel>
      <v-row class="my-10" justify="center">
        <v-col cols="12" md="4" v-for="(service, idx) in services" :key="idx">
          <v-hover>
            <template v-slot:default="{ hover }">
              <router-link
                dark
                color="secondary"
                class="text-decoration-none"
                text
                :to="{ name: service.link }"
              >
                <v-card
                  max-width="280"
                  height="250"
                  :elevation="hover ? 24 : 6"
                  class="mx-auto img"
                  color="primary"
                  justify="center"
                >
                  <v-card-title class="white--text text-center">
                    <v-row justify="center" class="mt-15">{{
                      service.name
                    }}</v-row>
                  </v-card-title>
                  <v-card-actions>
                    <v-row justify="center">
                      <v-btn
                        dark
                        color="secondary"
                        text
                        :to="{ name: service.link }"
                      >
                        Explore
                      </v-btn></v-row
                    >
                  </v-card-actions>
                </v-card>
              </router-link>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BookCarousel from "@/components/BookCarousel.vue";
import BreadCrumb from "../components/BreadCrumb.vue";
export default {
  name: "Bookstore",
  components: { BookCarousel, BreadCrumb },
  data: () => ({
    items: [
      {
        text: "Home",
        disabled: false,
        link: "Home",
      },
      {
        text: "Bookstore",
        disabled: true,
        link: "Bookstore",
      },
    ],

    services: [
      {
        name: "SHOP",
        image: "cardbookshop.jpg",
        link: "BookOrder",
      },
      {
        name: "BOOKCLUB",
        image: "Hall.jpg",
        link: "JoinBookclub",
      },
    ],
  }),
};
</script>
